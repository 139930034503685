'use strict'

import '../scss/main.scss'

window.$ = window.jQuery = require('jquery');

import 'bootstrap';

// import Swiper JS
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

if (document.querySelector('.mini-slider')) {
  new Swiper('.mini-slider', {
    modules: [Navigation, Pagination, Autoplay],
    direction: 'horizontal',
    loop: true,
    effect: 'slide',
    navigation: true,
    speed: 300,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
      // when window width is >= 320px
      400: {
        slidesPerView: 'auto',
        spaceBetween: 20
      },
    },
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}

window.designamite = {

  init: function()
  {

    designamite.global.init()
    
    designamite.cookie.init()

  },

  global: require('./methods/global'),
  
  cookie: require('./methods/cookie-bar'),

  setCookie: function(cname, cvalue, days) {
    var d = new Date();
    d.setTime(d.getTime() + (days*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  getCookie: function(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  },

}

designamite.init()