module.exports = {

  init () {
    
    this.cookie();
    
    //this.mainSlider()


  },
  
  cookie() {
    $(document).ready(function(){
      $.cookieBar({
      message: "This website uses cookies to ensure you get the best experience on our website. For more information visit our <a href='/privacy-policy'>Privacy Policy</a>.",
      acceptText: "I'm happy with that",
      fixed: true,
      policyButton: false,
      policyText: "Find out more",
      policyURL: "/privacy-policy"
    });
    });  
  
  },
  
  mainSlider() {
    const swiper = new Swiper('.main-slider', {
      modules: [Navigation, Autoplay],
      direction: 'horizontal',
      loop: true,
      effect: 'slide',
      navigation: true,
      speed: 300,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  
  },

}
